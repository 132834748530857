import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/headerinstitution"

const Partnership = () => (
  <Layout>
    <SEO title="Partnership" />

    <Header />
    <section className="section bg-dark text-light">
      <div className="container-fluid text-center py-4">
        <div className="row py-4 justify-content-center">
          <div className="col-5">
            <h3 className="text-uppercase">
              Study in Rwanda institution Ranking
            </h3>
          </div>
        </div>
      </div>
    </section>

    <section className="section py-8  px-8 pb-9">
      <div className="container-fluid">
        <div className="py-8">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h5 className="h2 text-primary font-weight-bold text-center text-uppercase">
                Study in Rwanda institution Ranking
              </h5>
              <p className="my-4 ">
                Study in Rwanda not only facilitates investors through the
                process but also helps keep our partners on their toes once they
                set up institutions. Study in Rwanda has a strategy by which its
                partners are ranked to ensure competence.
              </p>
              <p className="my-4">
                The Ministry of Education and other government bodies monitor
                the ranking process. This will ensure education standards
                provided are at per with the required standards of education in
                Rwanda.
              </p>

              <h5 className="h3 font-weight-bold text-primary text-center">
                WHAT WE CONSIDER WHEN RANKING INSTITUTIONS
              </h5>
              <p className="my-4">
                Study in Rwanda institution Rankings are based on institution
                performances.
                <br /> We consider:
                <ul>
                  <li>Research excellence</li>
                  <li>Specialization expertise</li>
                  <li>Admissions</li>
                  <li>Award numbers</li>
                  <li>Graduate employment</li>
                  <li>Internationalization</li>
                  <li>Measures of funding</li>
                  <li>Recommendation and reviews</li>
                </ul>
              </p>
              <span className="my-4">
                Would you like to vote or recommend an institution?{" "}
              </span>
              <span className="my-4">
                For more information talk to us: info@studyinrwanda.rw{" "}
              </span>
              <span>
                List or mention any universities you wish to recommend. Tell us
                why you recommend the university. How is it in terms of
                lectures, innovation, technology, investments towards the
                education sector?
              </span>
              <span>
                Also, write to us if you have encountered any challenges with
                our partners.
              </span>
              <span>
                Study in Rwanda awards the best institutions annually. We
                consider the best academic teaching calendar, its innovation in
                terms of teaching, its investments towards education and
                outsourcing academic skills.
              </span>
              <span>
                NB: votes are only considered valid if the voter gives a valid
                reason for recommendation.
              </span>

              <span className="my-4">
                For more information talk to us: info@studyinrwanda.rw{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Partnership
